<template>
  <base-layout
    show-back-btn="true"
    page-default-back-link="/"
    show-info="true"
  >
    <ion-text
      color="primary"
      class="ion-text-center title-headline"
    >
      <h1>Vagtskema</h1>
      <ion-text color="secondary">
        <h3>{{ dayName }} {{ day }}</h3>
      </ion-text>
    </ion-text>

    <ion-list
      v-if="day"
      class="work-list"
    >
      <template v-for="(property, key) in workPlanDay">
        <template v-if="key != 'Dato'">
          <ion-item
            button="true"
            color="secondary"
            @click="setActive(key)"
          >
            <ion-label>{{ key }}</ion-label>
            <ion-icon :icon="key == isActive ? icons.up : icons.down"></ion-icon>
          </ion-item>
          <work-plan-person
            v-if="key == isActive"
            :person="getPerson(property)"
          ></work-plan-person>
        </template>
      </template>
    </ion-list>

  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { callSharp, chevronDown, chevronUp } from "ionicons/icons";
import WorkPlanPerson from "../components/app/WorkPlanPerson";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonList,
  IonItemDivider,
} from "@ionic/vue";

export default {
  name: "Work",
  data() {
    return {
      dayName: null,
      day: null,
      workPlanDay: null,
      isActive: null,
      icons: {
        phone: callSharp,
        down: chevronDown,
        up: chevronUp,
      },
    };
  },
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
    IonList,
    IonItemDivider,
    WorkPlanPerson,
  },
  computed: {
    ...mapGetters(["workPlanData", "data"]),
  },
  mounted() {
    this.getDate();
  },
  methods: {
    setActive(key) {
      if (this.isActive == key) {
        this.isActive = null;
        return;
      }
      this.isActive = key;
    },
    getDate() {
      var datePlus = new Date();
      datePlus.setHours(datePlus.getHours() - 8);
      var days = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
      this.dayName = days[datePlus.getDay()];
      this.day = datePlus.toLocaleDateString("en-GB");

      this.workPlanDay = this.workPlanData.find((x) => x.Dato == this.day);
    },
    getPerson(ini) {
      if(ini) {
        var person = this.data.find((x) => x.Initialer.toUpperCase() == ini.toUpperCase());
        return person ? person : null;
      }
      return null;
    },
  },
};
</script>